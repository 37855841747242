@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  scroll-behavior: none;
}

body {
  position: fixed;
  width: 100%;
}

#root {
  height: 100%;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #000d;
  color: #fff;
  backdrop-filter: blur(4px);
  flex-direction: column;
}
